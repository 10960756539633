import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
  return (
    <>
    <div class="banner">
      <Header/>
        <div class="content">
        <h1>Impulsa tu negocio con nuestras soluciones</h1>
        <p>Optimiza tus procesos y alcanza tus objetivos con nuestra ayuda.</p>
        <p>¿Listo para avanzar?</p>
        <div>
            <a href="/Contacto"><button type="button"><span></span>Solicita una Demo</button></a>
            <a href="/Clientes"><button type="button"><span></span>Casos de Éxito</button></a>
        </div>


        </div>
    </div>
    <Footer/>
    </>
  );
}

export default Home;
