import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Whatsapp from './Whatsapp/Whatsapp'

const Header = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <>
      <header className="sticky-top">
        <div class="header">
          <div class="navbar">
            <a href="/">
              <img src="/LogoSistemify2RS.png" alt="Logo" class="logo" />
            </a>
            <nav ref={navRef}>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/Somos">Quienes Somos</a>
                </li>
                <li>
                  <a href="/Demos">Demo</a>
                </li>
                <li>
                  <a href="/Clientes">Clientes</a>
                </li>
                <li>
                  <a href="/Contacto">Contacto</a>
                </li>
              </ul>
              <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes />
              </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
              <FaBars />
            </button>
          </div>
        </div>
        <Whatsapp />

      </header>
    </>
  );
};

export default Header;
